import GradientText from 'components/GradientText';
import background from 'images/team-info-card.svg';

const TeamInfoCard = ({ data }) => {
    const { image, title, description } = data;

    return (
        <div className="team-info-card">
            <div className="team-info-card-inner">
                <img className="team-info-card__img" src={background} alt="team info image" />
                <div className="team-info-card__content">
                    <img className="team-info-card__icon" src={image} alt="icon" />
                    <div>
                        <GradientText textColor="white">
                            <em>{title}</em>
                        </GradientText>
                        <div className="body1 opacity-70">{description}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamInfoCard;
