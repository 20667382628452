import { LabelButton } from 'components/buttons/LabelButton/LabelButton';
import GradientText from 'components/GradientText';
import Banner from 'components/Banner';
import DistributionContacts from 'components/DistributionContacts';
import ContactsCards from './ContactsCards';
import DefaultForm from 'components/FormSection/Forms/DefaultForm';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef } from 'react';
import { selectContactsPage, selectContactsPageIsLoading } from 'store/slices/ContactsSlice';
import { fetchContacts } from 'store/slices/ContactsSlice';
import { scrollToSection } from 'helpers';
import { useLocation } from 'react-router-dom';
import { useAnimation } from 'hooks/useAnimation';
import Preloader from 'Layout/Preloader';
import {useEffect} from 'react';

const Contacts = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        document.title = "Контакты | Unifer X"; // Set the title here
    }, []);

    const {
        fs_title,
        fs_background,
        fs_button,
        international_tag,
        international_title,
        international_text,
        contacts,
        contact_tag,
        contact_title,
    } = useSelector(selectContactsPage);
    const loading = useSelector(selectContactsPageIsLoading);

    React.useEffect(() => {
        // @ts-ignore
        dispatch(fetchContacts());
    }, [dispatch]);

    React.useEffect(() => {
        if (location.hash === '#find') {
            scrollToSection('distribution-contacts', 100);
        }
    }, [location.hash]);

    const handleClick = () => scrollToSection('form-section');

    const container = useRef();

    useAnimation({
        items: ['.distribution-contacts', '.contacts-cards'],
        container,
        dependencies: [loading],
    });

    if (loading) return <Preloader />;

    return (
        <div ref={container}>
            <Banner title={fs_title} image={fs_background} buttonText={fs_button} onClickButton={handleClick} />

            <section className="container">
                <div className="inner-container type-2">
                    <div className="main-text-container">
                        <LabelButton>{international_tag}</LabelButton>
                        <GradientText className="has-animation">
                            <span dangerouslySetInnerHTML={{ __html: international_title }} />
                        </GradientText>
                        <div className="offset-left-4 max-width-760">
                            <GradientText size="super-small" className="has-animation">
                                {international_text}
                            </GradientText>
                        </div>
                    </div>
                </div>
            </section>

            <DistributionContacts />

            <section className="container">
                <div className="inner-container type-3">
                    <div className="main-text-container">
                        <LabelButton>{contact_tag}</LabelButton>
                        <div className="offset-left-4 max-width-760">
                            <GradientText size="middle" className="has-animation">
                                <span dangerouslySetInnerHTML={{ __html: contact_title }} />
                            </GradientText>
                        </div>
                    </div>

                    <ContactsCards data={contacts} />
                </div>
            </section>

            <DefaultForm />
        </div>
    );
};

export default Contacts;
