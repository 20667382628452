import React from 'react';
import NewsCard from './NewsCard';
import SubscribeCard from './SubscribeCard';
import './styles.scss';

const NewsCards = ({ posts = [] }) => (
    <div className="news-cards">
        {posts.map((item, index) => (
            <NewsCard key={index} data={item} />
        ))}
        <SubscribeCard />
    </div>
);

export default NewsCards;
