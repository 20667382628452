import WithSidebar from 'Layout/WithSidebar';
import { LabelButton } from 'components/buttons/LabelButton/LabelButton';
import GradientText from 'components/GradientText';
import Banner from 'components/Banner';
import PDFBox from 'components/PDFBox';
import ProductCatalogBox from 'components/ProductCatalogBox';
import DefaultForm from 'components/FormSection/Forms/DefaultForm';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchInformationMaterials,
    selectInformationMaterialFlyers,
    selectInformationMaterialsPage,
    fetchInformationMaterialsFlyers,
    fetchFilteredInformationMaterials,
    selectInformationMaterialsPageIsLoading,
    selectInformationMaterialsFlyersIsLoading,
} from 'store/slices/InformationMaterialsSlice';
import { useEffect, useRef, useState } from 'react';
import ButtonShowMore from 'components/Button/ButtonShowMore';
import React from 'react';
import NewDropdown from 'components/Dropdown';
import { selectProductsOptions } from 'store/slices/ProductsSlice';
import Filter from 'components/FilterNew';
import { scrollToSection } from 'helpers';
import { useAnimation } from 'hooks/useAnimation';
import Preloader from 'Layout/Preloader';
import { selectCropNutritionPage } from 'store/slices/CropNutritionSlice';

const InformationMaterials = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectInformationMaterialsPageIsLoading);
    const flyersIsLoading = useSelector(selectInformationMaterialsFlyersIsLoading);
    const { filter_name, product_select } = useSelector(selectCropNutritionPage);

    const {
        fs_title,
        fs_title_background,
        fs_view_button,
        agricultural_tag,
        agricultural_title,
        agricultural_description,
        catalogs = [],
    } = useSelector(selectInformationMaterialsPage);


    useEffect(() => {
        document.title = "Информационные материалы | Unifer X"; // Set the title here
    }, []);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchInformationMaterials());
        // @ts-ignore
    }, [dispatch]);

    const flyersData = useSelector(selectInformationMaterialFlyers);

    const getFlyers = () => {
        Object.entries(flyersData).forEach(([id]) => {
            // @ts-ignore
            dispatch(fetchInformationMaterialsFlyers({ id, page: 1 }));
        });
    };

    useEffect(() => {
        getFlyers();
        // eslint-disable-next-line
    }, [catalogs]);

    const productsOptions = useSelector(selectProductsOptions);

    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleFilterChange = option => setSelectedProduct(option);

    useEffect(() => {
        if (selectedProduct) {
            // @ts-ignore
            dispatch(fetchFilteredInformationMaterials({ product: selectedProduct.value }));
        }
    }, [dispatch, selectedProduct]);

    const handleReset = () => {
        setSelectedProduct(null);
        // @ts-ignore
        dispatch(fetchInformationMaterials());
    };

    const FilterComponent = () => (
        <Filter onReset={handleReset} hasSelected={selectedProduct} title={filter_name}>
            <NewDropdown
                label={product_select}
                options={productsOptions}
                onChange={handleFilterChange}
                selectedValue={selectedProduct}
            />
        </Filter>
    );

    const handleClick = () => scrollToSection('with-sidebar-layout__right', 100);

    const container = useRef();

    useAnimation({
        items: ['.box', '.with-sidebar-layout__left'],
        container,
        dependencies: [loading],
    });

    if (loading && flyersIsLoading) return <Preloader />;

    return (
        <div ref={container}>
            <Banner
                title={fs_title}
                image={fs_title_background}
                buttonText={fs_view_button}
                onClickButton={handleClick}
            />

            <section className="container">
                <div className="inner-container type-2">
                    <div className="main-text-container">
                        <LabelButton>{agricultural_tag}</LabelButton>
                        <GradientText className="has-animation">
                            <span dangerouslySetInnerHTML={{ __html: agricultural_title }} />
                        </GradientText>
                        <div className="offset-left-4 max-width-760">
                            <GradientText size="super-small" className="has-animation">
                                <span dangerouslySetInnerHTML={{ __html: agricultural_description }} />
                            </GradientText>
                        </div>
                    </div>
                </div>

                <WithSidebar Sidebar={<FilterComponent />}>
                    {catalogs.map(({ title, image, description, file, id }, index) => (
                        <React.Fragment key={index}>
                            <ProductCatalogBox file={file} title={title} image={image} description={description} />
                            {flyersData.hasOwnProperty(id) && (
                                <PDFBox data={flyersData[id].flyers}>
                                    <ButtonShowMore
                                        onClick={() =>
                                            dispatch(
                                                // @ts-ignore
                                                fetchInformationMaterialsFlyers({ id, page: flyersData[id].page + 1 }),
                                            )
                                        }
                                        next={flyersData[id].next}
                                    />
                                </PDFBox>
                            )}
                        </React.Fragment>
                    ))}
                </WithSidebar>
            </section>

            <DefaultForm />
        </div>
    );
};

export default InformationMaterials;
