import React, { useState } from 'react';
import './styles.scss';

const GeneralInfoBoxWithShowMore = ({ data, children = undefined }) => {
    const [visibleCount, setVisibleCount] = useState(1); // Start with 1 visible item

    const handleShowMore = () => {
        setVisibleCount(data.length); // Show all items on click
    };

    return (
        <div className="box general-info-box">
            {data?.slice(0, visibleCount).map((item, index) => (
                <div key={index} dangerouslySetInnerHTML={{ __html: item }}></div>
            ))}
            {data.length > visibleCount && (
                <button className="button read-more" onClick={handleShowMore}>
                    <span>Показать больше</span>
                </button>
            )}
        </div>
    );
};

export default GeneralInfoBoxWithShowMore;