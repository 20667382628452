import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBlogPage } from 'store/slices/BlogSlice';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import config from 'api/urls-config.json';
import FormControlInput from 'components/FormControls/Input';
import Checkbox from 'components/Checkbox';
import { isEmailValid } from 'components/FormControls/helpers';
import { API } from 'api/constants';

const Background = () => (
    <svg width="287" height="80" viewBox="0 0 287 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M286.069 80V16C286.069 7.16345 278.906 0 270.069 0H0C3.9104 0 7.6853 1.43201 10.6119 4.02554L73.7448 59.9745C76.6714 62.568 80.4463 64 84.3567 64H270.069C278.906 64 286.069 71.1635 286.069 80Z"
            fill="#ffffff"
        />
    </svg>
);

const SubscribeCard = () => {
    const {
        subscribe_title,
        subscribe_description,
        subscribe_policy_text,
        subscribe_thanks_description,
        subscribe_thanks_title,
        subscribe_tag,
    } = useSelector(selectBlogPage);
    const { cta_field_email_placeholder } = useSelector(selectTranslations);
    const [email, setEmail] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [checked, setChecked] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    const handleInputChange = email => setEmail(email);
    const handleSetChecked = () => setChecked(!checked);

    const handleSend = () => {
        setLoading(true);
        axios
            .post(`${API + config.subscribe}`, { email })
            .then(response => {
                setStatus(response.data.status);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    };

    const disabled = email === '' || !checked || !isEmailValid(email) || loading;

    return (
        <div className="subscribe-card">
            <div className="subscribe-card__label">
                <div className="subscribe-card__label-label">• {subscribe_tag}</div>
                <Background />
            </div>
            {status === 'success' ? (
                <div className="subscribe-card__content">
                    <h2>{subscribe_thanks_title}</h2>
                    <p className="body1 opacity-80">{subscribe_thanks_description}</p>
                </div>
            ) : (
                <div className="subscribe-card__content">
                    <h2 dangerouslySetInnerHTML={{ __html: subscribe_title }} />
                    <p className="body1 opacity-80">{subscribe_description}</p>
                    <div className="subscribe-form">
                        <div className="subscribe-form__input">
                            <FormControlInput
                                value={email}
                                onChange={handleInputChange}
                                label={undefined}
                                placeholder={cta_field_email_placeholder}
                                type="email"
                            />
                            <button className={`button send ${disabled ? 'disabled' : ''}`} onClick={handleSend} />
                        </div>
                        <Checkbox checked={checked} onChange={handleSetChecked}>
                            <span dangerouslySetInnerHTML={{ __html: subscribe_policy_text }} />
                        </Checkbox>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubscribeCard;
