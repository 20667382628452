import Filter from 'components/FilterNew';
import { useDispatch, useSelector } from 'react-redux';
import { selectProductsOptions } from 'store/slices/ProductsSlice';
import NewDropdown from 'components/Dropdown';
import {
    selectCurrentCountryFilterForTrialResults,
    selectCurrentProductFilterForTrialResults,
    selectTrialResultsPage,
    setCurrentCountryFilter,
    setCurrentProductFilter,
} from 'store/slices/TrialResults';
import { selectLastLocation, setLastLocation } from 'store/slices/UI-Slice';
import { ROUTES } from 'constants/routes';
import { selectCoutriesOptionsForFilter } from 'store/slices/languageSlice';

const FilterByProduct = () => {
    const dispatch = useDispatch();
    const currentProductFilter = useSelector(selectCurrentProductFilterForTrialResults);
    const currentCountryFilter = useSelector(selectCurrentCountryFilterForTrialResults);
    const countriesOptions = useSelector(selectCoutriesOptionsForFilter);
    const handleCountryFilterChange = option => {
        dispatch(setCurrentCountryFilter(option));
    };
    const handleProductsFilterChange = option => {
        dispatch(setCurrentProductFilter(option));
        dispatch(setLastLocation(null));
    };
    const { filter_name, product_select, country_select } = useSelector(selectTrialResultsPage);
    const productsOptions = useSelector(selectProductsOptions);

    const lastLocation = useSelector(selectLastLocation);
    const isLastLocationWasProduct = lastLocation?.pathname?.startsWith(ROUTES.products);

    if (isLastLocationWasProduct) {
        const fromProductFilterOption = productsOptions.find(item => item.value === lastLocation.state);
        dispatch(setCurrentProductFilter(fromProductFilterOption));
    }

    const handleReset = () => {
        dispatch(setCurrentProductFilter(null));
        dispatch(setCurrentCountryFilter(null));
        dispatch(setLastLocation(null));
    };

    return (
        <Filter
            onReset={handleReset}
            hasSelected={currentProductFilter || currentCountryFilter}
            title={filter_name}
            withoutSticky
        >
            <NewDropdown
                label={product_select}
                options={productsOptions}
                onChange={handleProductsFilterChange}
                selectedValue={currentProductFilter}
            />
            <NewDropdown
                label={country_select}
                options={countriesOptions}
                onChange={handleCountryFilterChange}
                selectedValue={currentCountryFilter}
            />
        </Filter>
    );
};

export default FilterByProduct;
