import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchWithLanguage = (name, request, condition) =>
    createAsyncThunk(
        name,
        async (params, { getState }) => {
            const { languageState } = getState();
            const response = await request({
                language: languageState.language.code,
                country: languageState.country.code,
                ...params,
            }).catch(e => {
                throw e;
            });

            return response.data;
        },
        { condition },
    );
