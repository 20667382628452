import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getProduct, getProducts, getMainProducts, getRelatedProducts } from 'api/Products';
import { fetchWithLanguage } from 'store/helpers';

export const fetchProducts = fetchWithLanguage('fetchProducts', getProducts, (_, { getState }) => {
    const { data } = getState().productsState.products;
    return !data;
});
export const fetchProduct = fetchWithLanguage('fetchProduct', getProduct);
export const fetchMainProducts = fetchWithLanguage('fetchMainProducts', getMainProducts);
export const fetchRelatedProducts = fetchWithLanguage('fetchRelatedProducts', getRelatedProducts);

const productsSlice = createSlice({
    name: 'productsSlice',
    initialState: {
        products: {},
        mainProducts: {},
        productContent: {},
        relatedProducts: {
            data: [],
            next: false,
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchMainProducts.fulfilled, (state, action) => {
                state.mainProducts = action.payload;
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.products = action.payload;
            })
            .addCase(fetchRelatedProducts.fulfilled, (state, action) => {
                const { payload, meta } = action;
                state.relatedProducts.next = payload.data.next;

                if (meta.arg.page === 1) {
                    state.relatedProducts.data = payload.data.data;
                } else {
                    state.relatedProducts.data.push(...payload.data.data);
                }
            })
            .addCase(fetchProduct.pending, state => {
                state.productContent = {};
                state.relatedProducts = {
                    data: [],
                    next: false,
                };
            })
            .addCase(fetchProduct.fulfilled, (state, action) => {
                state.productContent = action.payload;
            });
    },
});

export const selectProductsPage = createSelector(
    [state => state.productsState.products],
    products => products.data || {},
);

export const selectProductsPageIsLoading = createSelector(
    [state => state.productsState.products],
    products => products?.status !== 'success',
);

export const selectProductContent = createSelector(
    [state => state.productsState.productContent],
    productContent => productContent.data || {},
);

export const selectProductContentIsLoading = createSelector(
    [state => state.productsState.productContent],
    productContent => productContent?.status !== 'success',
);

export const selectMainProducts = createSelector([state => state.productsState.mainProducts], data => data.data || []);

export const selectProductsOptions = createSelector(
    [selectMainProducts],
    data =>
        data?.map(product => ({
            value: product.slug,
            name: product.preview_title,
        })) || [],
);

export const selectRelatedProducts = createSelector(
    [state => state.productsState.relatedProducts],
    relatedProducts => relatedProducts || {},
);

export default productsSlice.reducer;
