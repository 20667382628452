import './styles.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';

const MobileSocialBlock = () => {
    const { footer_instagram_link, footer_facebook_link, footer_youtube_link } = useSelector(selectTranslations);
    return (
        <div className="social-media-section">
            <p>Мы в соцсетях</p>
            <div className="social-media-wrapper">
                <Link target="_blank" to={footer_instagram_link} className="button vk" />
                <Link target="_blank" to={footer_facebook_link} className="button tg" />
                <Link target="_blank" to={footer_youtube_link} className="button rutube" />
            </div>
        </div>
    );
};
export default MobileSocialBlock;
