import { useEffect, useState } from 'react';
import useMediaQuery from 'hooks/useMediaQuery';
import office from 'images/office.jpg';
import './styles.scss';
import { useSelector } from 'react-redux';
import { selectContactsPage } from 'store/slices/ContactsSlice';
import NewDropdown from 'components/Dropdown';

const Content = ({ data }) => {
    const { sales_photo, sales_name, sales_description, sales_phone, sales_email } = data || {};

    return (
        data && (
            <div className="distribution-contacts__contacts-wrapper__distributor">
                {sales_photo && <img src={sales_photo} alt="{sales_name}" />}
                {sales_name && <p className="description-demibold">{sales_name}</p>}
                {sales_description && <p className="body1">{sales_description}</p>}
                {sales_phone && (
                    <a
                        className="distribution-contacts__contact button-text distributor-phone"
                        href={`tel:${sales_phone}`}
                        title="phone"
                    >
                        {sales_phone}
                    </a>
                )}
                {sales_email && (
                    <a
                        className="distribution-contacts__contact button-text distributor-email"
                        href={`mailto:${sales_email}`}
                         title="email"
                    >
                        {sales_email}
                    </a>
                )}
            </div>
        )
    );
};

const Map = ({ image }) => <img src={image || office} alt="map" />;

const DistributionContacts = () => {
    const {
        countries = [],
        country_description,
        country_select_text,
        region_select_text,
    } = useSelector(selectContactsPage);

    const [selectedCountry, setSelectedCountry] = useState(undefined);
    const [selectedRegion, setSelectedRegion] = useState(undefined);

    useEffect(() => {
        setSelectedCountry(countries[0]);
    }, [countries]);

    useEffect(() => {
        setSelectedRegion(selectedCountry?.regions[0]);
    }, [selectedCountry]);

    const handleCountryChange = item => setSelectedCountry(item);

    const handleRegionChange = item => setSelectedRegion(item);

    const isTablet = useMediaQuery('max-width: 1023px');

    return (
        <section className="container">
            <div className="distribution-contacts">
                <div className="distribution-contacts__contacts-wrapper">
                    <div className="distribution-contacts__contacts-wrapper__contacts">
                        <p className="description-medium">{country_description}</p>
                        <div className="dropdowns">
                            <NewDropdown
                                label={country_select_text}
                                options={countries}
                                selectedValue={selectedCountry}
                                onChange={handleCountryChange}
                            />
                            {!!selectedCountry?.regions.length && (
                                <NewDropdown
                                    label={region_select_text}
                                    options={selectedCountry.regions}
                                    selectedValue={selectedRegion}
                                    onChange={handleRegionChange}
                                />
                            )}
                        </div>
                    </div>
                    {!isTablet && <Content data={selectedRegion || selectedCountry} />}
                </div>

                <Map image={selectedRegion?.image || selectedCountry?.image} />

                {isTablet && <Content data={selectedRegion || selectedCountry} />}
            </div>
        </section>
    );
};

export default DistributionContacts;
