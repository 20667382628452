import { Link } from 'react-router-dom';
import React, {useEffect} from 'react';

const ProductItem = ({ icon, slug, title }) => {

    useEffect(() => {
       
        if (document.location.pathname.indexOf('crop-nutrition-programs') > 0) {
            document.title = 'Программы питания растений | Unifer X';
        }
        
    })
    return (
        <Link to={slug} className="product-item">
            <div className="product-item__image">{icon && <img src={icon} alt={title} />}</div>
            <div className="product-item__name">
                <p className="body2">{title}</p>
            </div>
        </Link>
    );
};

export default ProductItem;
