import './styles.scss';
import { ReactComponent as X } from 'assets/icons/X.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as Globe } from 'assets/icons/GlobeSimple.svg';

export const getIcon = iconName => {
    const icons = {
        close: <X />,
        search: <Search />,
        globe: <Globe />,
    };
    return icons[iconName];
};

const HeaderButton = ({ onClick = undefined, size = '', iconName = 'close', text = '' }) => {
    const classes = `header-button header-button-${iconName} header-button--${size}`;

    return (
        <button onClick={onClick} className={classes}>
            {getIcon(iconName)}
            {text && <span>{text}</span>}
        </button>
    );
};

export default HeaderButton;
