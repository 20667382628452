import axios from 'axios';
import config from 'api/urls-config.json';
import { API } from 'api/constants';

export const getLanguages = () => {
    return axios.get(`${API + config.languages_list}`);
};

export const getCountries = () => {
    return axios.get(`${API + config.countries_list}`);
};

export const getTranslations = params => {
    return axios.get(`${API + config.pages.translation}`, { params });
};
