import Tabs from 'components/Tabs';
import './styles.scss';
import TabPane from 'components/Tabs/TabPane';
import ProductItems from 'components/ProductItems';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    fetchTrialResultsForProductForOthers,
    fetchTrialResultsForProductForCurrentCountry,
    selectTrialResultsForProductForCurrentCountry,
    selectTrialResultsForProductForOthers,
} from 'store/slices/TrialResults';
import { type_trials } from 'api/constants';
import { useEffect, useState } from 'react';
import { selectCurrentCountry } from 'store/slices/languageSlice';
import { ROUTES } from 'constants/routes';
import ButtonShowMore from 'components/Button/ButtonShowMore';
import { selectProductContent } from 'store/slices/ProductsSlice';

const ProductItemsBoxWithTabs = ({ title }) => {
    const dispatch = useDispatch();
    const { slug } = useParams();
    const [tabPage1, setTabPage1] = useState(1);
    const [tabPage2, setTabPage2] = useState(1);

    const tab1 = useSelector(selectTrialResultsForProductForCurrentCountry);
    const tab2 = useSelector(selectTrialResultsForProductForOthers);

    const trials_country = useSelector(selectCurrentCountry);
    const { code } = trials_country;

    const { trial_in_country_tab, trial_in_other_country_tab } = useSelector(selectProductContent);

    useEffect(() => {
        dispatch(
            // @ts-ignore
            fetchTrialResultsForProductForCurrentCountry({
                product: slug,
                type: type_trials,
                trials_country: code,
                page: tabPage1,
            }),
        );
    }, [dispatch, slug, code, tabPage1]);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchTrialResultsForProductForOthers({ product: slug, type: type_trials, page: tabPage2 }));
    }, [dispatch, slug, code, tabPage2]);

    const handleShowMoreTab1 = () => setTabPage1(tabPage1 + 1);
    const handleShowMoreTab2 = () => setTabPage2(tabPage2 + 1);

    const tabsData = [
        {
            title: trial_in_country_tab,
            data: tab1.data || [],
            next: tab1.next,
            onClick: handleShowMoreTab1,
        },
        {
            title: trial_in_other_country_tab,
            data: tab2.data || [],
            next: tab2.next,
            onClick: handleShowMoreTab2,
        },
    ];

    return (
        <div className="box product-box">
            {title && <h3>{title}</h3>}
            <Tabs>
                {tabsData.map(tab => (
                    <TabPane key={tab.title} title={tab.title}>
                        <ProductItems data={tab.data} baseRoute={ROUTES.trialResults} />
                        <ButtonShowMore next={tab.next} onClick={tab.onClick} />
                    </TabPane>
                ))}
            </Tabs>
        </div>
    );
};

export default ProductItemsBoxWithTabs;
