import Filter from 'components/FilterNew';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCropNutritionPage,
    selectCurrentProductFilterForCropNutrition,
    setCurrentProductFilter,
} from 'store/slices/CropNutritionSlice';
import { selectProductsOptions } from 'store/slices/ProductsSlice';
import NewDropdown from 'components/Dropdown';
import { selectLastLocation, setLastLocation } from 'store/slices/UI-Slice';
import { ROUTES } from 'constants/routes';

const FilterByProduct = () => {
    const dispatch = useDispatch();
    const currentProductFilter = useSelector(selectCurrentProductFilterForCropNutrition);
    const handleProductsFilterChange = option => {
        dispatch(setCurrentProductFilter(option));
        dispatch(setLastLocation(null));
    };
    const { filter_name, product_select } = useSelector(selectCropNutritionPage);
    const productsOptions = useSelector(selectProductsOptions);

    const lastLocation = useSelector(selectLastLocation);
    const isLastLocationWasProduct = lastLocation?.pathname?.startsWith(ROUTES.products);

    if (isLastLocationWasProduct) {
        const fromProductFilterOption = productsOptions.find(item => item.value === lastLocation.state);
        dispatch(setCurrentProductFilter(fromProductFilterOption));
    }

    const handleReset = () => {
        dispatch(setCurrentProductFilter(null));
        dispatch(setLastLocation(null));
    };

    return (
        <Filter onReset={handleReset} hasSelected={currentProductFilter} title={filter_name} withoutSticky>
            <NewDropdown
                label={product_select}
                options={productsOptions}
                onChange={handleProductsFilterChange}
                selectedValue={currentProductFilter}
            />
        </Filter>
    );
};

export default FilterByProduct;
