import useMediaQuery from 'hooks/useMediaQuery';
import HeaderNavigationItem from './HeaderNavigationItem';
import MobileSocialBlock from '../MobileSocialBlock';
import { useSelector } from 'react-redux';
import { ROUTES } from 'constants/routes';
import { selectMainProducts } from 'store/slices/ProductsSlice';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { generateNavigationData } from './helpers';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const HeaderNavigation = () => {
    const isTablet = useMediaQuery('max-width: 1023px');
    const products = useSelector(selectMainProducts);
    const location = useLocation();

    const {
        menu_company_text,
        menu_products_text,
        menu_resources_text,
        menu_contacts_text,
        menu_about_text,
        menu_news_text,
        menu_career_text,
        menu_all_products_btn,
        menu_crop_nutrition_text,
        menu_trial_results_text,
        menu_information_materials_text,
        menu_all_resources_btn,
    } = useSelector(selectTranslations);

    const formated_products = products.map(product => ({
        icons: [product.logo],
        name: product.preview_title,
        link: ROUTES.products + '/' + product.slug,
    }));

    const navData = generateNavigationData({
        menu_company_text,
        menu_products_text,
        menu_resources_text,
        menu_contacts_text,
        menu_about_text,
        menu_news_text,
        menu_career_text,
        menu_all_products_btn,
        menu_crop_nutrition_text,
        menu_trial_results_text,
        menu_information_materials_text,
        menu_all_resources_btn,
        formated_products,
    });

    const initialState = navData
        .filter(category => !!category.items)
        .map(category => ({ name: category.name, isOpen: false }));

    const [mobileState, setMobileState] = useState(initialState);

    const handleClickMobile = name => {
        const newState = mobileState.map(item => ({ ...item, isOpen: item.name === name ? !item.isOpen : false }));
        setMobileState(newState);
    };

    useEffect(() => {
        setMobileState(initialState);
    }, [location]);

    return (
        <div className="header-nav">
            {navData.map(category => (
                <HeaderNavigationItem
                    key={category.id}
                    category={category}
                    mobileState={mobileState}
                    onClick={handleClickMobile}
                />
            ))}
            {isTablet && <MobileSocialBlock />}
        </div>
    );
};
export default HeaderNavigation;
