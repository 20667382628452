import React, { useState } from 'react';
import BenefitsCards from 'components/BenefitsCards';
import YoutubeLink from 'components/YoutubeLink';
import './styles.scss';

const BenefitsBoxShowMore = ({ title, benefits, video = null, children = undefined }) => {
    const [showAll, setShowAll] = useState(false); // State to track if all videos should be shown

    const handleShowMore = () => {
        setShowAll(true); // Show all videos on button click
    };

    return (
        <div className="box">
            {title && <h3>{title}</h3>}
            <BenefitsCards data={benefits} />
            {children}

            {video && (showAll ? video : video.slice(0, 1)).map((videoData, index) => (
                <YoutubeLink 
                    key={index} 
                    image={videoData.benefits_preview_video} 
                    href={videoData.benefits_video} 
                    title={videoData.benefits_preview_text} 
                />
            ))}

            {video && video.length > 1 && !showAll && (
                <button className="button read-more button__margin-top" onClick={handleShowMore}>
                    <span>Показать больше</span>
                </button>
            )}
        </div>
    );
};

export default BenefitsBoxShowMore;