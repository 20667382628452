import React from 'react';
import TagItem from './TagItem';
import './styles.scss';

const TagsFilter = ({ tags = [], selectedTag, onClick }) => {

    const uniqueTags = tags.filter((tag, index, self) =>
        index === self.findIndex((t) => (
            t.type === tag.type
        ))
    );

    return (
        <div className="tags-filter">
            {uniqueTags.length && uniqueTags.map(tag => (
                <TagItem key={tag.type} tag={tag} selectedTag={selectedTag} onClick={onClick} />
            ))}
        </div>
    );
};

export default TagsFilter;
