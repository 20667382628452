import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getCookie } from 'helpers';

const uislice = createSlice({
    name: 'uislice',
    initialState: {
        isNavigationItemHover: false,
        isCookieAgree: getCookie('cookieIsAgree') || false,
        lastLocation: {
            pathname: null,
            state: {},
        },
    },
    reducers: {
        setLastLocation: (state, action) => {
            state.lastLocation = action.payload;
        },
        setNavigationItemHover: (state, action) => {
            state.isNavigationItemHover = action.payload;
        },
        setAgreeCookie: state => {
            document.cookie = 'cookieIsAgree=true';
            state.isCookieAgree = true;
        },
    },
});

export const selectIsNavigationItemHover = createSelector([state => state.ui], data => data.isNavigationItemHover);

export const selectIsCookieAgree = createSelector([state => state.ui], data => data.isCookieAgree);

export const selectLastLocation = createSelector([state => state.ui], data => data.lastLocation);

export const { setNavigationItemHover, setAgreeCookie, setLastLocation } = uislice.actions;

export default uislice.reducer;
