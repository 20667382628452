import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/reset.css';
import './styles/common.css';
import './styles/main.scss';
import { store } from 'store/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import ScrollToTop from 'hooks/scrollToTop';
import CookieBanner from 'components/CookieBanner';

ReactDOM.createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <BrowserRouter>
            <ScrollToTop />
            <App />
            <CookieBanner />
        </BrowserRouter>
    </Provider>,
);
