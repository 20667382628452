import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import WithSidebar from 'Layout/WithSidebar';
import { LabelButton } from 'components/buttons/LabelButton/LabelButton';
import GradientText from 'components/GradientText';
import ProductItems from 'components/ProductItems';
import Banner from 'components/Banner';
import DefaultForm from 'components/FormSection/Forms/DefaultForm';
import {
    fetchTrialResults,
    fetchTrialResultsPageList,
    // selectAllCropTrialsOptions,
    // selectCurrentProductFilterForTrialResults,
    selectTrialResultsIsLoading,
    selectTrialResultsPage,
    selectTrialResultsPageList,
    // setCurrentProductFilter,
} from 'store/slices/TrialResults';
import { ROUTES } from 'constants/routes';
// import NewDropdown from 'components/Dropdown';
// import { selectProductsOptions } from 'store/slices/ProductsSlice';
import { type_trials } from 'api/constants';
// import { selectCoutriesOptionsForFilter } from 'store/slices/languageSlice';
// import Filter from 'components/FilterNew';
import { scrollToSection } from 'helpers';
import Preloader from 'Layout/Preloader';
import { useAnimation } from 'hooks/useAnimation';

const TrialResults = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectTrialResultsIsLoading);
    // const currentProductFilter = useSelector(selectCurrentProductFilterForTrialResults);
    useEffect(() => {
        document.title = "Результаты испытаний | Unifer X"; // Set the title here
    }, []);

    const {
        fs_title,
        fs_title_background,
        fs_view_button,
        agricultural_tag,
        agricultural_title,
        agricultural_description,
        // filter_name,
        // product_select,
        // crop_select,
        // country_select,
    } = useSelector(selectTrialResultsPage);

    const crops = useSelector(selectTrialResultsPageList);

    useEffect(() => {
        // @ts-ignore
        dispatch(fetchTrialResultsPageList({ all: true, type: type_trials }));
        // @ts-ignore
        dispatch(fetchTrialResults());

        // dispatch(setCurrentProductFilter(null));
    }, [dispatch]);

    // const productsOptions = useSelector(selectProductsOptions);
    // const cropsOptions = useSelector(selectAllCropTrialsOptions);
    // const countriesOptions = useSelector(selectCoutriesOptionsForFilter);

    // const [selectedCrop, setSelectedCrop] = useState(null);
    // const [selectedCountry, setSelectedCountry] = useState(null);

    // const handleProductsFilterChange = option => dispatch(setCurrentProductFilter(option));
    // const handleCropsFilterChange = option => setSelectedCrop(option);
    // const handleCountryFilterChange = option => setSelectedCountry(option);

    // useEffect(() => {
    //     if (currentProductFilter || selectedCrop || selectedCountry) {
    //         dispatch(
    //             // @ts-ignore
    //             fetchTrialResultsPageList({
    //                 all: true,
    //                 type: type_trials,
    //                 slug: selectedCrop?.value || null,
    //                 product: currentProductFilter?.value || null,
    //                 trials_country: selectedCountry?.value || null,
    //             }),
    //         );
    //     }
    // }, [dispatch, currentProductFilter, selectedCrop, selectedCountry]);

    // const handleReset = () => {
    //     setSelectedCrop(null);
    //     setSelectedCountry(null);
    //     dispatch(setCurrentProductFilter(null));
    //     // @ts-ignore
    //     dispatch(fetchTrialResultsPageList({ all: true, type: type_trials }));
    // };

    // const FilterComponent = () => (
    //     <Filter
    //         onReset={handleReset}
    //         hasSelected={currentProductFilter || selectedCrop || selectedCountry}
    //         title={filter_name}
    //     >
    //         <NewDropdown
    //             label={product_select}
    //             options={productsOptions}
    //             onChange={handleProductsFilterChange}
    //             selectedValue={currentProductFilter}
    //         />
    //         <NewDropdown
    //             label={crop_select}
    //             options={cropsOptions}
    //             onChange={handleCropsFilterChange}
    //             selectedValue={selectedCrop}
    //         />
    //         <NewDropdown
    //             label={country_select}
    //             options={countriesOptions}
    //             onChange={handleCountryFilterChange}
    //             selectedValue={selectedCountry}
    //         />
    //     </Filter>
    // );

    const handleClick = () => scrollToSection('box', 100);

    const container = useRef();

    useAnimation({
        items: ['.with-sidebar-layout'],
        container,
        dependencies: [loading],
    });

    if (loading) return <Preloader />;

    return (
        <div ref={container}>
            <Banner
                title={fs_title}
                image={fs_title_background}
                buttonText={fs_view_button}
                onClickButton={handleClick}
            />

            <section className="container">
                <div className="inner-container type-2">
                    <div className="main-text-container">
                        <LabelButton>{agricultural_tag}</LabelButton>
                        <GradientText className="has-animation">
                            <span dangerouslySetInnerHTML={{ __html: agricultural_title }} />
                        </GradientText>
                        <div className="offset-left-4 max-width-760">
                            <GradientText size="super-small" className="has-animation">
                                <span dangerouslySetInnerHTML={{ __html: agricultural_description }} />
                            </GradientText>
                        </div>
                    </div>
                </div>

                {/* <WithSidebar Sidebar={<FilterComponent />}>
                    <div className="box">
                        <ProductItems data={crops} baseRoute={ROUTES.trialResults} />
                    </div>
                </WithSidebar> */}
                <div className="box">
                    <ProductItems data={crops} baseRoute={ROUTES.trialResults} withSixItems />
                </div>
            </section>

            <DefaultForm />
        </div>
    );
};

export default TrialResults;
