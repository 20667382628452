import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getTranslations } from 'api/Languages';
import { fetchWithLanguage } from 'store/helpers';

export const fetchTranslations = fetchWithLanguage('fetchTranslations', getTranslations);

const transltionsSlice = createSlice({
    name: 'transltionsSlice',
    initialState: {
        data: {},
        translationsIsLoading: false,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchTranslations.pending, state => {
            state.translationsIsLoading = true;
        });
        builder.addCase(fetchTranslations.fulfilled, (state, action) => {
            state.data = action.payload;
            state.translationsIsLoading = false;
        });
    },
});

export const selectTranslations = createSelector([state => state.translationsState.data], data => data.data || {});

export const selectTranslationsIslLoading = createSelector(
    [state => state.translationsState.translationsIsLoading],
    translationsIsLoading => translationsIsLoading,
);

export const selectPostsTypeTranslations = createSelector([state => state.translationsState.data], data => {
    const { blog_type_Article, blog_type_Event, blog_type_News, blog_type_Promo, blog_type_Research } = data?.data;
    return {
        Article: blog_type_Article,
        Event: blog_type_Event,
        News: blog_type_News,
        Promo: blog_type_Promo,
        Research: blog_type_Research,
    };
});

export default transltionsSlice.reducer;
