import { InstagramCard } from 'components/InstagramCard/InstagramCard';
import blackInstagram from 'images/icons/blackInstagram.svg';
import './styles.scss';
import { useSelector } from 'react-redux';
import { selectHomepage } from 'store/slices/HomepageSlice';
import { Link } from 'react-router-dom';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { useRef } from 'react';
import useMediaQuery from 'hooks/useMediaQuery';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Instagram = () => {
    const { instagram_photo_1, instagram_photo_2, instagram_photo_3, instagram_photo_4, instagram_title } =
        useSelector(selectHomepage);
    const { footer_instagram_link } = useSelector(selectTranslations);

    const container = useRef();
    const isTablet = useMediaQuery('max-width: 1023px');

    const speed = isTablet ? [-250, -180, -100, -150] : [-300, -230, -200, -270];

    useGSAP(
        () => {
            let targets = gsap.utils.toArray(['.insta-anim-card']);
            targets.forEach((item, index) => {
                gsap.to(item, {
                    yPercent: speed[index],
                    ease: 'none',
                    scrollTrigger: {
                        trigger: '.instagram-news',
                        scrub: 1,
                    },
                });
            });
        },
        { scope: container },
    );

    useGSAP(
        () => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.instagram-news',
                    start: 'top 70%',
                    end: 'top',
                    scrub: 1,
                },
            });
            tl.from('.h1', { opacity: 0, y: 10 });
            tl.fromTo('.instagram-news', { backgroundSize: '0%' }, { backgroundSize: isTablet ? '95%' : '63%' });
        },
        { scope: container },
    );

    return (
        <section className="container" ref={container}>
            <div className="instagram-news">
                <div className="instagram-image-wrapper">
                    <div className="h1">{instagram_title}</div>
                    <Link target="_blank" to={footer_instagram_link}>
                        <img src={blackInstagram} alt="instagram" />
                    </Link>
                </div>
                <div className="first-instagram-card insta-anim-card">
                    <InstagramCard image={instagram_photo_1} />
                </div>
                <div className="second-instagram-card insta-anim-card">
                    <InstagramCard image={instagram_photo_2} />
                </div>
                <div className="third-instagram-card insta-anim-card">
                    <InstagramCard image={instagram_photo_3} />
                </div>
                <div className="fourth-instagram-card insta-anim-card">
                    <InstagramCard image={instagram_photo_4} />
                </div>
            </div>
        </section>
    );
};

export default Instagram;
