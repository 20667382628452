import React, { useEffect } from 'react';
import './styles.scss';
// import { ReactComponent as Icon1 } from 'assets/icons/circle-gradient-phone.svg';
// import { ReactComponent as Icon2 } from 'assets/icons/circle-gradient-email.svg';
// import { ReactComponent as Icon3 } from 'assets/icons/circle-gradient-pin.svg';

// const data = [
//     { icon: <Icon1 />, title: 'Phone', text: '+49 173 762 7701' },
//     { icon: <Icon2 />, title: 'E-mail', text: 'info@uniferx.com' },
//     { icon: <Icon3 />, title: 'Office address', text: 'Strandstraße 6 18211 Ostseebad Nienhagen, Deutschland' },
// ];

const ContactsCards = ({ data = [] }) => {
    useEffect(() => {
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Uniferx",
            "url": "https://uniferx.ru",
            "logo": "https://api.uniferx.ru/storage/logo.svg", // Замените на URL вашего логотипа
            "contactPoint": [
                {
                    "@type": "ContactPoint",
                    "telephone": "+7 918 272 18 58",
                    "contactType": "Customer Service",
                    "areaServed": "RU",
                    "availableLanguage": "Russian"
                },
                {
                    "@type": "ContactPoint",
                    "email": "valentina@uniferx.com",
                    "contactType": "Customer Service",
                    "availableLanguage": "Russian"
                }
            ],
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "им. Дзержинского, д 8/1",
                "addressLocality": "Краснодар",
                "addressRegion": "Краснодар",
                "postalCode": "350020",
                "addressCountry": "RU"
            }
        };

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.innerHTML = JSON.stringify(schemaData);
        document.head.appendChild(script);

        // Убираем скрипт при размонтировании компонента
        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <div className="contacts-cards">
            {
                data.map(({ icon, title, text }) => (
                    <div data-title={title} className="contact-card" key={title}>
                        <div className="body3 contact-card__title">
                            <img src={icon} alt="icon" />
                            {title}
                        </div>
                        <div className="description-demibold">{text}</div>
                    </div>
                ))
            }
        </div>
    );
};

export default ContactsCards;