import { Button } from 'components/buttons/Button/Button';
import { ROUTES } from 'constants/routes';
import background from 'images/career-card.svg';
import { useSelector } from 'react-redux';
import { selectAboutUsPage } from 'store/slices/AboutUsSlice';

const TeamCareerCard = () => {
    const { team_card_button, team_card_text, team_card_title } = useSelector(selectAboutUsPage);

    return (
        <div className="team-career-card">
            <div className="team-career-card-inner">
                <img className="team-career-card__img" src={background} alt="career image" />
                <div className="team-career-card__content">
                    <div>
                        <div className="h2">{team_card_title}</div>
                        <p className="body1 opacity-80">{team_card_text}</p>
                    </div>
                    <Button href={ROUTES.career} type="button-arrow">
                        {team_card_button}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default TeamCareerCard;
