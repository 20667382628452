import './styles.scss';
import { useState } from 'react';

const NavigationItem = ({ index, activeIndex, text, onClick }) => (
    <div data-index={index} data-active={activeIndex}
        className={`navigation-sidebar__item ${activeIndex === index ? 'navigation-sidebar__active' : ''}`}
        onClick={onClick}
    >
        <p className="button-text">{text}</p>
    </div>
);

const NavigationSideBar = ({ items, activeIndex, refs, includesTitle = '' }) => {
    const [currentActiveIndex, setCurrentActiveIndex] = useState(activeIndex);

    const handleClick = (index) => {
        setCurrentActiveIndex(index);
        window.scrollTo({
            top: refs[index].current.getBoundingClientRect().top + window.scrollY - 100,
            behavior: 'smooth',
        });
    };

    return (
        <div className="navigation-sidebar">
            {items.map((text, index) => {
                if (text === includesTitle) {
                    return false;
                }
                return (
                    <NavigationItem
                        key={index}
                        index={index}
                        activeIndex={currentActiveIndex}
                        text={text}
                        onClick={() => handleClick(index)}
                    />
                );
            })}
        </div>
    );
};

export default NavigationSideBar;