import Logo from 'components/Logo';
import './styles.scss';
import HeaderNavigation from './Navigation';
import CountrySelector from 'components/HeaderNew/CountrySelector';
import Search from './Search';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsNavigationItemHover } from 'store/slices/UI-Slice';
import { useLocation } from 'react-router-dom';

const BurgerIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="40" viewBox="0 0 56 40" fill="none">
        <line x1="16" y1="15.25" x2="41" y2="15.25" stroke="white" strokeWidth="1.5" />
        <line x1="16" y1="23.25" x2="41" y2="23.25" stroke="white" strokeWidth="1.5" />
    </svg>
);

const HeaderNew = () => {
    const location = useLocation();
    const [mobileMenuIsOpen, setMobileMenuOpen] = React.useState(false);
    const [countryIsOpen, setCountryIsOpen] = React.useState(false);
    const [isSearchOpen, setSearchOpen] = React.useState(false);

    const onToggleMobileMenu = () => setMobileMenuOpen(!mobileMenuIsOpen);
    const isNavigationItemHover = useSelector(selectIsNavigationItemHover);

    React.useEffect(() => {
        setMobileMenuOpen(false);
    }, [location]);

    React.useEffect(() => {
        if (isSearchOpen) {
            setCountryIsOpen(false);
            setMobileMenuOpen(false);
        }
    }, [isSearchOpen]);

    React.useEffect(() => {
        if (countryIsOpen) {
            setSearchOpen(false);
            setMobileMenuOpen(false);
        }
    }, [countryIsOpen]);

    React.useEffect(() => {
        if (isNavigationItemHover) {
            setSearchOpen(false);
            setCountryIsOpen(false);
        }
    }, [isNavigationItemHover]);

    React.useEffect(() => {
        if (mobileMenuIsOpen) {
            setSearchOpen(false);
            setCountryIsOpen(false);
        }
    }, [mobileMenuIsOpen]);

    return (
        <>
            {mobileMenuIsOpen ? <div className="overlay" onClick={onToggleMobileMenu} /> : false}
            <header>
                <div className="container">
                    <div className={`header ${mobileMenuIsOpen ? 'mobile-menu-isOpen' : ''}`}>
                        <div className="header__main">
                            <div className="header-logo">
                                <Logo />
                            </div>
                            <HeaderNavigation />
                            <div className="header-finish">
                                <CountrySelector isOpen={countryIsOpen} setIsOpen={setCountryIsOpen} />
                                <Search isOpen={isSearchOpen} setIsOpen={setSearchOpen} />
                                <button className="burger-button" onClick={onToggleMobileMenu}>
                                    <BurgerIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default HeaderNew;
