import { useSelector } from 'react-redux';
import { selectIsSearchEnd, selectSearchData } from 'store/slices/SearchSlice';
import ResultItemCard from './ResultItemCard';
import { ROUTES } from 'constants/routes';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import ResultBlock from './ResultBlock';
import pdf from 'images/icons/pdf.svg';

const SearchResults = () => {
    const data = useSelector(selectSearchData);
    const isSearchEnd = useSelector(selectIsSearchEnd);

    const {
        menu_search_results_text,
        menu_search_not_found_text,
        menu_search_products_text,
        menu_search_all_products_text,
        menu_search_resources_text,
        menu_search_all_resources_text,
        menu_search_nutrition_programs_text,
        menu_search_all_nutrition_programs_text,
        menu_search_trial_results_text,
        menu_search_all_trial_results_text,
        menu_search_posts_text,
        menu_search_all_posts_text,
        menu_search_vacancies_text,
        menu_search_all_vacancies_text,
        menu_crop_nutrition_text,
        menu_information_materials_text,
        menu_trial_results_text,
        menu_career_text,
    } = useSelector(selectTranslations);

    const { products, cropsNutrition, cropsTrials, posts, catalogs, vacancies, flyers } = data;

    const notFound =
        !products?.length &&
        !cropsNutrition?.length &&
        !cropsTrials?.length &&
        !posts?.length &&
        !catalogs?.length &&
        !flyers?.length &&
        !vacancies?.length;

    const title =
        notFound && isSearchEnd
            ? menu_search_not_found_text
            : isSearchEnd && !notFound
            ? menu_search_results_text
            : 'Searching...';

    const catalogAndFlyers = [...catalogs, ...flyers];

    return (
        <div className="search-results">
            <div className="search-results-title">{title}</div>
            <div className="search-results-content">
                <ResultBlock
                    data={products}
                    title={menu_search_products_text}
                    link={ROUTES.products}
                    linkText={menu_search_all_products_text}
                >
                    {products.map(({ slug, preview_title, logo }) => (
                        <ResultItemCard
                            key={slug}
                            title={preview_title}
                            slug={ROUTES.products + '/' + slug}
                            img={logo}
                            desc={menu_search_products_text}
                        />
                    ))}
                </ResultBlock>
                <ResultBlock
                    data={cropsNutrition}
                    title={menu_search_nutrition_programs_text}
                    link={ROUTES.cropNutritionPrograms}
                    linkText={menu_search_all_nutrition_programs_text}
                >
                    {cropsNutrition.map(({ icon, slug, title }) => (
                        <ResultItemCard
                            key={slug}
                            title={title}
                            slug={ROUTES.cropNutritionPrograms + '/' + slug}
                            img={icon}
                            desc={menu_crop_nutrition_text}
                        />
                    ))}
                </ResultBlock>
                <ResultBlock
                    data={cropsTrials}
                    title={menu_search_trial_results_text}
                    link={ROUTES.trialResults}
                    linkText={menu_search_all_trial_results_text}
                >
                    {cropsTrials.map(({ icon, slug, title }) => (
                        <ResultItemCard
                            key={slug}
                            title={title}
                            slug={ROUTES.trialResults + '/' + slug}
                            img={icon}
                            desc={menu_trial_results_text}
                        />
                    ))}
                </ResultBlock>
                <ResultBlock
                    data={posts}
                    title={menu_search_posts_text}
                    link={ROUTES.news}
                    linkText={menu_search_all_posts_text}
                >
                    {posts.map(({ slug, title, title_background, type }) => (
                        <ResultItemCard
                            key={slug}
                            title={title}
                            slug={ROUTES.news + '/' + slug}
                            img={title_background}
                            desc={type}
                        />
                    ))}
                </ResultBlock>
                <ResultBlock
                    data={vacancies}
                    title={menu_search_vacancies_text}
                    link={ROUTES.career}
                    linkText={menu_search_all_vacancies_text}
                >
                    {vacancies.map(({ slug, title, title_background }) => (
                        <ResultItemCard
                            key={slug}
                            title={title}
                            slug={ROUTES.career + '/' + slug}
                            img={title_background}
                            desc={menu_career_text}
                        />
                    ))}
                </ResultBlock>
                <ResultBlock
                    data={catalogAndFlyers}
                    title={menu_search_resources_text}
                    link={ROUTES.informationMaterials}
                    linkText={menu_search_all_resources_text}
                >
                    {catalogs?.map(({ id, title, image }) => (
                        <ResultItemCard
                            key={id}
                            title={title}
                            slug={ROUTES.informationMaterials}
                            img={image}
                            desc={menu_information_materials_text}
                        />
                    ))}
                    {flyers?.map(({ title }, index) => (
                        <ResultItemCard
                            key={index}
                            title={title}
                            slug={ROUTES.informationMaterials}
                            img={pdf}
                            desc={menu_information_materials_text}
                        />
                    ))}
                </ResultBlock>
            </div>
        </div>
    );
};

export default SearchResults;
