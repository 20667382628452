import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getBlog, getPost, getPosts } from 'api/Blog';
import { fetchWithLanguage } from '../helpers';

export const fetchPost = fetchWithLanguage('fetchPost', getPost);
export const fetchBlog = fetchWithLanguage('fetchBlog', getBlog, (_, { getState }) => {
    const { data } = getState().blogState.blog;
    return !data;
});
export const fetchLatestPostsForHomepage = fetchWithLanguage('fetchLatestPostsForHomepage', getPosts);
export const fetchPostsForNews = fetchWithLanguage('fetchPostsForNews', getPosts);
export const fetchPostsSlider = fetchWithLanguage('fetchPostsSlider', getPosts, (_, { getState }) => {
    const { data } = getState().blogState.articleSliderPosts;
    return !data.length;
});

const blogSlice = createSlice({
    name: 'blogSlice',
    initialState: {
        blog: {},
        latestPostHomepage: {},
        latestPostHomepageIsloading: false,
        newsPagePosts: {
            data: [],
            next: false,
        },
        postContent: {},
        articleSliderPosts: {
            data: [],
            next: false,
        },
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchLatestPostsForHomepage.pending, state => {
                state.latestPostHomepageIsloading = true;
            })
            .addCase(fetchLatestPostsForHomepage.fulfilled, (state, action) => {
                state.latestPostHomepage = action.payload;
                state.latestPostHomepageIsloading = false;
            })
            .addCase(fetchPostsForNews.fulfilled, (state, action) => {
                const { payload, meta } = action;
                state.newsPagePosts.next = payload.next;
                if (meta.arg.page === 1) {
                    state.newsPagePosts.data = payload.data;
                } else {
                    state.newsPagePosts.data.push(...payload.data);
                }
            })
            .addCase(fetchBlog.fulfilled, (state, action) => {
                state.blog = action.payload;
            })
            .addCase(fetchPost.pending, (state, action) => {
                state.postContent = {};
            })
            .addCase(fetchPost.fulfilled, (state, action) => {
                state.postContent = action.payload;
            })
            .addCase(fetchPostsSlider.fulfilled, (state, action) => {
                state.articleSliderPosts = action.payload;
            });
    },
});

export const selectBlogPage = createSelector([state => state.blogState.blog], blog => blog.data || {});

export const selectLatestPostHomepage = createSelector(
    [state => state.blogState.latestPostHomepage],
    latestPostHomepage => latestPostHomepage.data || [],
);
export const selectLatestPostHomepageIsLoading = createSelector(
    [state => state.blogState.latestPostHomepageIsloading],
    latestPostHomepageIsloading => latestPostHomepageIsloading,
);
export const selectPostForNews = createSelector(
    [state => state.blogState.newsPagePosts],
    newsPagePosts => newsPagePosts,
);

export const selectPostContent = createSelector(
    [state => state.blogState.postContent],
    postContent => postContent?.data || {},
);
export const selectPostContentIsLoading = createSelector(
    [state => state.blogState.postContent],
    postContent => postContent?.status !== 'success',
);

export const selectArticleSliderPosts = createSelector(
    [state => state.blogState.articleSliderPosts],
    articleSliderPosts => articleSliderPosts.data || [],
);

export const selectBlogPageIsLoading = createSelector(
    [state => state.blogState.blog],
    blog => blog?.status !== 'success',
);

export default blogSlice.reducer;
